import Carousel from '@mozaic-ds/freemarker/js/modules/_carousel.js';
const DEFAULT_NAVIGATION_CAROUSEL = 'js-default-navigation-carousel';
class DefaultNavigation extends lm.Composant {
  constructor(id) {
    super(id);
  }
  init() {
    const elemCarouselDefaultNavigation = document.querySelector(`.${DEFAULT_NAVIGATION_CAROUSEL}`);
    if (elemCarouselDefaultNavigation) {
      try {
        new Carousel(elemCarouselDefaultNavigation);
      } catch (error) {
        throw new Error(`Error : ${error}`);
      }
    }
  }
}
lm.DOMReady(function () {
  const defaultNavigation = new DefaultNavigation('defaultnavigation');
  defaultNavigation.init();
});